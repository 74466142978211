<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'APP',
}
</script>

<style lang="scss">
@import "~@/assets/fonts/font.css";
#app {
  font-family: YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, div, ul, li, input, button, img, textarea, p, h1{
  margin: 0;
  padding: 0;
  border: none;
  outline:none;
  -webkit-tap-highlight-color: transparent; //禁用按压时有背景颜色
  height: auto;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
img{
  font-size: 0;
  line-height: 0;
}
input::-webkit-input-placeholder{
  font-size: 14px;
  color: #999999;
}
//去掉input自动填充的黄色背景
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}
//清除浮动
.clearfix::after {
	content: '';
	display: table;
	clear: both;
}
/* 兼容IE低版本 */
.clearfix {
	*zoom: 1;
}
//单行省略号
.a-single-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
//多行省略号
.vertical-ellipsis{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
//禁用双击选中
.disable-double-click-selected{
  -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  -khtml-user-select:none;/*早期浏览器*/
  user-select:none;
}
</style>
