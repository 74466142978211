import Axios from 'axios'
// import https from 'https'
import MD5 from 'js-md5';
import Store from '@/store'
import { isPC as IsPC } from '@/utils/util'

//域名设置
let urlHttp = window.location.href
let urlHead = 'https'
if(urlHttp.includes('https')){
	urlHead = 'https'
}

const api_url = urlHead+'://newkskapi.kskstudy.com';
	// const  api_url = 'http://www.new-ksk-api.com';
let auth = '';



//添加请求拦截器        跟这个 是同时进行的
Axios.interceptors.request.use(config => { //在发送请求之前执行方法
	if(config.useLog){ //请求如果需要用户登录
		//判断用户是否登录
		let userInfo = localStorage.getItem('user');

		if(userInfo){
			userInfo = JSON.parse(userInfo);
			//auth = userInfo.id + '.' + Math.round(new Date().getTime()/1000).toString() + '.' + MD5(userInfo.id + '.' + Math.round(new Date().getTime()/1000).toString() + '.api_password');
			auth = JSON.parse(localStorage.getItem('auth')).token;
		}
	}else{
		auth = 'Zgkc ksk.' + Math.round(new Date().getTime()/1000).toString() + '.' + MD5('ksk.' + Math.round(new Date().getTime()/1000).toString() + '.common_password');
	}

	config.headers['Content-Type'] = "application/json; charset=utf-8";
	config.headers['Authorization'] = auth;

	if(process.env.NODE_ENV == "development"){   //开发环境请求头
        // config.headers['origin2'] = 'http://www.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'https://cxgzspx.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'https://ymjg.kskstudy.com/';  //本地开发
        // config.headers['origin2'] = 'https://zgzxw.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'https://ywdh.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'https://cyrc.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://cxgzspx.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://cyrc.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://bdagh.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://npx.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://master.kskstudy.com';  //本地开发
        // config.headers['origin2'] = 'http://jymx.kskstudy.com';  //缙云美校
        // config.headers['origin2'] = 'http://zyhyrcfzy.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'http://jiangsuzgjycypx.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'https://kskweb.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'http://sun2.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'http://vip.skills.kjcxchina.com';  //本地开发
		// config.headers['origin2'] = 'http://master.son.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'http://aqgl.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'http://dgjnjl.kskstudy.com';  //本地开发
		// config.headers['origin2'] = 'https://zscqbh.kskstudy.com/';  //本地开发
		// config.headers['origin2'] = 'https://bqbzz.kskstudy.com';  //北汽
		// config.headers['origin2'] = 'https://dayuan2023.kskstudy.com';  //大源
		// config.headers['origin2'] = 'https://jiangsuzgjycypx.kskstudy.com';  //江苏
		// config.headers['origin2'] = 'https://culrcx.kskstudy.com';  //劳动关系学院
		// config.headers['origin2'] = 'https://ghgbpx.kskstudy.com';  //
		// config.headers['origin2'] = 'https://qnzgjxy.kskstudy.com';  // 黔南州
		config.headers['origin2'] = 'https://qhgjxy.kskstudy.com';  //青海
    }
	
	config = formatConfig(config);
	return config
}, error => { //请求错误时执行方法
	return Promise.reject(error)
})

//添加响应拦截器
Axios.interceptors.response.use(response => { //响应数据执行方法
	successState(response);
	return response.data
}, error => {
	errorState(error.response)
});
//接口请求成功返回的方法
function successState(res) {
	if (res.data.code && res.data.code == 3 || res.data.code == 4) {
		if(IsPC()){
			Store.commit('ShowLoginModal') //显示登录提示
			sessionStorage.removeItem('userInfo')
		}else{
			Store.commit('ShowMLoginModal') //显示登录提示
			sessionStorage.removeItem('userInfo')
		}
	}
	return
}
/*二次封装axios*/
//错误执行方法
function errorState(res) {
	// 如果http状态码正常，则直接返回数据
	if(res && res.status && (res.status == 200 || res.status == 304 || res.status == 400)) {
		return res
		// 如果不需要除了data之外的数据，可以直接 return response.data
	} else if(res && res.status && res.data && res.status == 500) {
		// Vue.prototype.$Message.error(res.data.msg);
	} else if(res && res.statusText) {
		// Vue.prototype.$Message.error(res.statusText);
	}
}

//格式化请求数据
function formatConfig(config) {
	if(['get', 'delete'].includes(config.method) && config.params) { //get请求
		Object.keys(config.params).forEach(k => {
			if(config.params[k] === "") {
				delete config.params[k]
			}
			config.params[k]=HandleSpecial(config.params[k]);
		});
		return config;
	}
	if(config.method == "put" || config.method == "post") { //put请求和delete请求post请求
		if(config.data && config.headers['Content-Type'] == "application/x-www-form-urlencoded") {
			config.data = getParamsData(config.data);
			return config;
		}
		if(config.data) {
			Object.keys(config.data).forEach(k => {
				if(config.data[k] === "" || config.data[k] === null) {
					delete config.data[k]
				} else {
					config.data[k] = HandleSpecial(config.data[k]);
				}
			});
		}
	}
	return config;
}
//删除value是空的项  这个
function getParamsData(_data) {
	//let index = -1;
	let arr = [];
	//let length = 0;
	let res = {};
	let _str = "";
	if(!_data) {
		return res;
	}
	arr = _data.split('&');
	//length = arr.length;
	arr.forEach(t => {
		res[t.split('=')[0]] = t.split('=')[1];
	});
	Object.keys(res).forEach(k => {
		if((typeof(res[k]) == 'string')) {
			res[k] = HandleSpecial(res[k]);
		}
		if(res[k] !== "") {
			_str += ((_str ? "&" : "")) + k + "=" + res[k];
		}
	})
	res = _str;
	return res;
}
//特殊字符串处理,除去
function HandleSpecial(params) {
	if(params && (typeof(params) == 'string')) {
		params = fifterStr(params);
	}
	if(params && Array.isArray(params)) {
		params.forEach(t => {
			if(t) {
				t = HandleSpecial(t);
			}
		});
	}
	if(params && Object.prototype.toString.call(params) === '[Object Object]') {
		Object.keys(params).forEach(k => {
			if(params[k]) {
				params[k] = HandleSpecial(params[k]);
			}
		})
	}
	return params;
}
//去掉多余字符串
function fifterStr(str) {
	str = str.replace(/[\n\r\t]/g, "");
	str = str.replace(/(^\s*)|(\s*$)/g, "");
	return str
}

//设置默认请求头
Axios.defaults.headers = {
	'Content-Type': 'application/x-www-form-urlencoded',
}
Axios.defaults.baseURL = api_url + "/api";

export default Axios;