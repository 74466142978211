//判断是否为微信浏览器
export const IsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') != -1;
    
    if (isWeixin) {
        return true;
    }else{
        return false;
    }
}

// 验证手机号
export const isRegular = (data) => {
    return /^1[3456789]\d{9}$/.test(data)
}
// 验证手机号 / 座机格式  区号之后用'-'隔开
export const isPhone = (data) => {
    return /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(17[0-9])|(18[0,5-9])|(19[0-9]))\d{8}$|^0\d{2,3}-?\d{7,8}$/.test(data)
}
// 验证邮箱
export const VerificationEmail = (data) => {
    return /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/.test(data)
    // return /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/.test(data)
}
// 验证身份证号
export const VerificationCard = (data) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}
// 验证地图经度
export const VerificationLon = (data) => {
    let res = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
    return res.test(data)
}
// 验证地图纬度
export const VerificationLat = (data) => {
    let res = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
    return res.test(data)
}
// 验证姓名 （2-4个汉字）
export const isName = (data) => {
    let res = /^[\u4E00-\u9FA5]{2,4}$/
    return res.test(data)
}
// 验证6-20 数字加字母组合的密码
export const isPassWord = (data) => {
    let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,}$/;
    return reg.test(data)
}
//日期时间计算,获取当前时间，特定时间前的日期
export const getBeforeDate = (n) => {
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    var s = '';
    if (day <= n) {
        if (mon > 1) {
            mon = mon - 1;
        } else {
            year = year - 1;
            mon = 12;
        }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
    return s;
}
// 转换标准时间为年-月-日
export const transformTime = (data) => {
    let d = new Date(data);
    let mon = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    let minutes = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
    let datetime = d.getFullYear() + '-' + mon + '-' + minutes;
    return datetime
}
// 转换标准时间为年月
export const transformTimeDate = (data) => {
    let d = new Date(data);
    let mon = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    let minutes = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
    let datetime = d.getFullYear() + '-' + mon;
    return datetime
}
// 转换标准时间为年-月-日-时分秒 datetimerange
export const transformDateTimerange = (data, isS) => {
    let d = new Date(data);
    let mon = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
    let day = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
    let hours = null
    let minutes = null
    let seconds = null
    if (isS) {
        hours = 23
        minutes = 59
        seconds = 59
    } else {
        hours = d.getHours() > 9 ? d.getHours() : "0" + d.getHours()
        minutes = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes()
        seconds = d.getSeconds() > 9 ? d.getSeconds() : "0" + d.getSeconds()
    }
    let datetime = d.getFullYear() + '-' + mon + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return datetime
}
// 数组去重
export const uniq = (array) => {
    let temp = [];
    let index = [];
    let l = array.length;
    for (let i = 0; i < l; i++) {
        for (let j = i + 1; j < l; j++) {
            if (array[i] === array[j]) {
                i++;
                j = i;
            }
        }
        temp.push(array[i]);
        index.push(i);
    }
    return temp;
}

//base64转图片下载
export const downloadFile = (fileName, content) => {
    //base64转blob
    let base64ToBlob = (code) => {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;

        let uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }
    let aLink = document.createElement('a');
    let blob = base64ToBlob(content); //new Blob([content]);

    let evt = document.createEvent("HTMLEvents");
    evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错 事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);

    // aLink.dispatchEvent(evt);
    //aLink.click()
    aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); //兼容火狐
}
//日期比较
export const dateCompare = (date_s, date_e) => {
    var beginTime = date_s + '';
    var endTime = date_e + '';
    var beginTimes = beginTime.substring(0, 10).split('-');
    var endTimes = endTime.substring(0, 10).split('-');
    beginTime = beginTimes[1] + '-' + beginTimes[2] + '-' + beginTimes[0] + ' ' + beginTime.substring(10, 19);
    endTime = endTimes[1] + '-' + endTimes[2] + '-' + endTimes[0] + ' ' + endTime.substring(10, 19);
    var disparityTime = (Date.parse(endTime) - Date.parse(beginTime)) / 3600 / 1000;
    var disparityTime = (Date.parse(endTime) - Date.parse(beginTime)) / 3600 / 1000;
    if (disparityTime < 0) {
        // 开始时间大
        return false;
    } else if (disparityTime > 0) {
        // 结束时间大
        return true
    } else if (disparityTime == 0) {
        // 时间相等
        return true
    } else {
        // 格式不对
        return true;
    }
}

//判断客户端
export const isPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];

    let flag = true;

    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break
        }
    }

    return flag
};