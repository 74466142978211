<template>
  <div class="m-home">
    <router-view />
    <Login v-if="mLoginModal.show" :set_info="set_info" />
    <Tip :text="tipModal.text" :ico="tipModal.ico" :showBtns="tipModal.showBtns" v-if="tipModal.show" :fun="tipModal.fun" :confirmFun="tipModal.confirmFun" :confirmBtnText="tipModal.confirmBtnText" :cancelFun="tipModal.cancelFun" :cancelBtnText="tipModal.cancelBtnText" />

    <div class="mask_box" v-if="comState">
      <div class="com_data">
        <div class="ban_input_box">
          <div class="banner">
          <img src="@/assets/mask_box/m_ban2.png" alt="">
        </div>
        <div class="input_list">
          <ul>
            <li>
              <p class="icon"><img src="@/assets/mask_box/m_icon1.png" alt=""></p>
              <p class="input"><input type="text" v-model="formData1.company" placeholder="请输入企业名称"></p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/mask_box/m_icon2.png" alt=""></p>
              <p class="input"><input type="text"  v-model="formData1.name" placeholder="请输入负责人姓名"></p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/mask_box/m_icon3.png" alt=""></p>
              <p class="input"><input type="text"  v-model="formData1.phone" placeholder="请输入手机号"></p>
            </li>
          </ul>
        </div>
        <div class="btn">
          <p  @click="submitBtn1()">立即申请</p>
        </div>
        </div>
         <div class="close" @click="closeComState()"><img src="@/assets/mask_box/close2.png" alt=""></div>
      </div>
    </div>
    <div class="mask_box" v-if="registerState">
      <div class="com_data">
        <div class="ban_input_box">
          <div class="banner">
          <img src="@/assets/mask_box/m_ban1.png" alt="">
        </div>
        <div class="input_list">
          <ul>
           <li>
              <p class="icon"><img src="@/assets/mask_box/m_icon2.png" alt=""></p>
              <p class="input"><input type="text"  v-model="formData2.name" placeholder="请输入负责人姓名"></p>
            </li>
            <li>
              <p class="icon"><img src="@/assets/mask_box/m_icon3.png" alt=""></p>
              <p class="input"><input type="text"  v-model="formData2.phone" placeholder="请输入手机号"></p>
            </li>
          </ul>
        </div>
        <div class="btn">
          <p @click="submitBtn2()">立即申请</p>
        </div>
        </div>
         <div class="close" @click="registerState=false"><img src="@/assets/mask_box/close2.png" alt=""></div>
      </div>
    </div>
 
  </div>
</template>
<script>
import Login from "@/m/common/login.vue";
import Tip from "@/m/common/tip.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "MHome",
  components: {
    Login,
    Tip,
  },
  data() {
    return {
      comState:false,
      registerState:false,
      formData1:{
        company:'',
        name:'',
        phone:'',
        type:1,
      },
      formData2:{
        name:'',
        phone:'',
        type:2,
      },
      set_info:'',
    };
  },
  mounted() {
    this.getWebsiteInfo()
    this.getWebInfo()    
  },
  methods: {
    ...mapMutations([ 'ShowTipModal']), //登录弹窗

    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        this.set_info = res.data.site_info;
        document.getElementById("linIcon").href = this.set_info.site_icon;
        document.getElementById("titleName").innerText= this.set_info.name;
        if(this.set_info.id==49){
            setTimeout(()=>{
              this.comState = true
            },3000)
          }
        });
    },
    getWebInfo(){
      let hostLink = window.location.host=='localhost:8081'?'kskweb.kskstudy.com':window.location.host
      this.$axios.post(`/v1/home/getStatus`, { host: hostLink }).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("websiteState", JSON.stringify(res.data));
            this.$store.state.websiteState = res.data;
          }
        });
    },

    submitBtn1(){
      this.formData1.source_url = window.location.host
      this.$axios.post(`/v1/subcribe`,this.formData1).then(res=>{
        if(res.code==0){
          this.formState = false
          this.ShowTipModal({
            text:'提交成功'
          })
        }
      })
      this.comState = false
      setTimeout(()=>{
        this.registerState = true
      },60000)
    },
    submitBtn2(){
      this.formData2.source_url = window.location.host
      this.$axios.post(`/v1/subcribe`,this.formData2).then(res=>{
        if(res.code==0){
          this.formState = false
          this.ShowTipModal({
            text:'提交成功'
          })
          this.registerState = false
        }
      })
    },  
    closeComState(){
      this.comState = false
      setTimeout(()=>{
        this.registerState = true
      },60000)
    }

  },
  computed: {
    ...mapState(["tipModal", "mLoginModal", "userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  height: 100vh;
  overflow-y: auto;
}
.mask_box{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 999;
  .com_data{
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .ban_input_box{
      width: 80%;
      margin: 0 auto;
      background: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 39px;
      .banner{
        width: 100%;
        position: relative;
        top: -80px;
        img{
          width: 100%;
        }
      }
      .input_list{
        margin: -80px 35px 0;
        li{
          border-bottom: 1px solid #DBD9D9;
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          padding-top: 30px;
          p.icon{
           width: 50px;
              height: 50px;
            img{
              width: 50px;
              height: 50px;
            }
          }
          p.input{
            margin-left: 10px;
            input{
              font-size: 16px;
              color: #222;
            }
          }
        }
      }
      .btn{
        width: 319px;
        height: 78px;
        background: #254ED4;
        border-radius: 78px;
        margin: 58px auto 39px;
        text-align: center;
        line-height: 78px;
        font-size: 30px;
        color: #FEFEFE;
      }
    }
    div.close{
    
      margin: 38px auto;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      border: 1px solid #fff;
      img{
        width: 24px;
        height: 24px;
        margin: 12px auto;
        display: block;
      }
    }
  }

}
</style>
<style >
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; 
  -webkit-box-orient: vertical;
}
.overflow_y {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all; 
  -webkit-box-orient: vertical;
}
.flex{
  display: flex;
  align-items: center;
}
</style>