<template>
  <div :class="'fenzhan_'+siteInfo.id">
    <div class="bottom" :class="siteInfo.id == 106 ? 'new_bottom' : ''">
      <div class="footer" :class="{'show': showState=='old'}">
        <ul class="blogroll" v-if="blogroll && blogroll.length > 0">
          <li class="link">友情链接:</li>
          <li class="link" v-for="link in blogroll" :key="link.id">
            <a :href="link.link" target="_blank">{{ link.title }}</a>
          </li>
        </ul>
        <div class="content">
          <!-- <img :src="bottomInfo.footer_logo?bottomInfo.footer_logo:bottomInfo.site_logo" alt="logo" class="logo"> -->
          <div class="center">
            <div class="title">{{ bottomInfo.name }} <span v-if="bottomInfo.id ==73">400-0123-823</span></div>
            <div class="tip" :class="{'has-com': bottomInfo.company}" v-if="bottomInfo.company && bottomInfo.id != 66">主办单位：{{bottomInfo.company}}</div>
            <div class="tip has-com" v-if="siteInfo.id == 107">主办单位：青海省总工会</div>
            <div class="tip" :class="{'has-com': bottomInfo.company||siteInfo.id==107}" v-if="bottomInfo.id !=67">
              <span>技术支持：{{ bottomInfo.fae?bottomInfo.fae:'中工科创' }}</span>
              <span>Copyright © 2015-2023 kskstudy.com</span>
              <span>All Rights Reserved</span>
              <span>京ICP备<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                {{ bottomInfo.beianhao }}
              </a></span>
            </div>
          </div>
          
          <div class="code_box" v-if="bottomInfo.id ==73">
            <div>
              <p class="img"><img src="@/assets/index/code_img1.png" alt=""></p>
              <p class="text">微信联系</p>
            </div>
            <div>
              <p class="img"><img src="@/assets/index/zgykt_dyh.jpg" alt=""></p>
              <p class="text">关注微信公众号</p>
            </div>
          </div>
          <!-- <div class="service" v-if="bottomInfo.id !=67 && bottomInfo.id !=80">
            <div class="title">联系方式</div>
            <div class="phone">{{ bottomInfo.phone }}</div>
          </div>
          <img class="weixincode" :src="bottomInfo.weixin_code" /> -->
        </div>
      </div>
      <div class="footer new_footer" :class="{'show': showState=='new'}">
        <div class="link_list">
          <div class="list_first">
            <div class="logo">
              <p class="img"><img src="@/assets/deep_logo.png" alt="" /></p>
              <p class="text">
                技能强国开发团队打造<br />
                全国多省市人社局指定培训平台 <br />
                打造全员提升的应用技术大学
              </p>
            </div>
            <div class="ul_list">
              <ul class="aboutUs">
                <li>近期活动</li>
                <li>
                  <a href="https://mp.weixin.qq.com/s/jR0kqHjWWxwZk3_S06zuvw"
                    >机工免费出书</a
                  >
                </li>
                <li><a href="http://npx.kskstudy.com/">大国工匠技术讲堂</a></li>
                <li>
                  <a href="javascript:;">3M职业健康学习</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>

                <!-- <li><a href="">（渠道活动）</a></li> -->
              </ul>
              <ul class="aboutUs">
                <li>培训方案</li>
                <li>
                  <a href="javascript:;">专业学习班</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
                <li><a href="http://open.kskstudy.com/">企业大学</a></li>
                <li>
                  <a href="http://npx.kskstudy.com/#/course_learn">大师来了</a>
                </li>
                <li><a href="http://open.kskstudy.com/#/NewsDetail?id=17">企业研学</a></li>
                <li>
                  <a href="javascript:;">定制培训方案</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
              </ul>
              <ul class="aboutUs">
                <li>活动工具</li>
                <li>
                  <a href="javascript:;">练兵竞赛工具</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
                <li>
                  <a href="javascript:;">内部调研系统</a>

                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
                <li>
                  <a href="javascript:;">投票活动工具</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
                <li>
                  <a href="javascript:;">评选申报工具</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
                <li>
                  <a href="javascript:;">定制活动工具</a>
                  <div class="code_img">
                    <img src="@/assets/code_img.png" alt="" />
                  </div>
                </li>
              </ul>
              <ul class="aboutUs">
                <li>合作类型</li>
                <li>
                  <a href="javascript:;">内容合作</a>
                  <div class="code_img">
                    <img src="@/assets/code_img2.png" alt="" />
                  </div>
                </li>
                <li><a href="http://landing.kjcxchina.com/pages/form.html">师资合作</a></li>
                <li>
                  <a href="javascript:;">渠道合作</a>
                  <div class="code_img">
                    <img src="@/assets/code_img2.png" alt="" />
                  </div>
                </li>
                <li><a href="http://open.kskstudy.com">企业入驻</a></li>
              </ul>
              <ul class="aboutUs">
                <li>关于我们</li>
                <li>
                  <a href="http://open.kskstudy.com/#/CompanyProfile">公司简介</a>
                </li>
                <li><a href="http://open.kskstudy.com/#/News">新闻动态</a></li>
                <li>
                  <a href="javascript:;">干货资料</a>
                  <div class="code_img">
                    <img src="@/assets/code_img3.jpg" alt="" />
                  </div>
                </li>
              </ul>
              <ul>
                <li>咨询服务</li>
                <li>
                  <!-- <img src="@/assets/" alt=""> -->
                </li>
                <li style="opacity: 0.6">400-0123-823</li>
                <!-- <li><a href="">用户服务：13121559280（景老师）</a></li>
                <li><a href="">运营支持：13717741496（沈老师）</a></li> -->
              </ul>
            </div>
          </div>
          <div class="list_sec">
            <p class="title">友情链接</p>
            <!-- <ul>
              <li>
                <a href="https://skills.kjcxchina.com/"
                  >人社培训补贴的线上学习平台</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=14"
                  >职业院校的培训计划方案</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=10"
                  >政府机构的在线学习培训与监管</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=9"
                  >工会体系职工职业技能提升方案</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=8"
                  >协会线上培训服务平台</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=12"
                  >大型企业的一体化培训体系</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=15"
                  >中小企业的企业管理与培训</a
                >
              </li>
              <li>
                <a href="http://open.kskstudy.com/#/NewsDetail?id=16"
                  >人资/培训企业在线学习平台</a
                >
              </li>
            </ul> -->
            <ul  v-if="blogroll && blogroll.length > 0">
          <!-- <li class="link">友情链接:</li> -->
          <li class="link" v-for="link in blogroll" :key="link.id">
            <a :href="link.link" target="_blank">{{ link.title }}</a>
          </li>
        </ul>
          </div>
          <div class="deep_text">
            技术支持：中工科创Copyright © 2015-2023 kskstudy.comAll Rights
            Reserved京ICP备<a href=" https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">16012224号-3 </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  props: {
    bottomInfo: Object,
  },
  data() {
    return {
      blogroll: "",
      showState: '',
      siteInfo:'',
    };
  },
 
  mounted() {
    if(localStorage.getItem('siteInfo')){
      this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    }
    this.getBlogroll(); //获取友情链接
    this.showState = this.bottomInfo.id==49?'new':'old';
  },
  methods: {
    
    //获取友情链接
    getBlogroll() {
      this.$axios.post(`/v1/home/relevanceLink`).then((res) => {
        this.blogroll = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
a{
  color: #fff;
}
.bottom {
  width: 100%;
  background-color: #222;
  position: relative;
  z-index: 1;
  .footer{
    display: none;
    &.show{
      display: block;
    }
  }
  .content,
  .blogroll {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .blogroll {
    display: flex;
    padding-top: 30px;
    margin-bottom: -10px;
    .link,
    .link a {
      font-size: 14px;
      color: #666;
    }
    .link {
      margin-right: 30px;
    }
  }
  .content {
    height: 80px;
  }
  .logo {
    width: auto;
    height: 80px;
    margin-right: 40px;
  }
  .center {
    .title {
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      span{
        margin-left: 20px;
      }
    }
    .tip {
      font-size: 12px;
      color: #999;
      margin-top: 40px;
      &.has-com{
        margin-top: 20px;
      }
      span {
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .weixincode {
    width: auto;
    height: 80px;
  }
  .service {
    color: #fff;
    margin-left: auto;
    margin-right: 40px;
    .title {
      font-size: 14px;
      padding-left: 20px;
      background: url(~@/assets/phone.png) left center no-repeat;
      background-size: auto 20px;
    }
    .phone {
      font-size: 20px;
      font-weight: bold;
      margin-top: 38px;
    }
  }
}
.new_bottom {
  background-color: #EDEDED;
  .blogroll {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #CACACA;
  }
  .content {
    height: 120px;
  }
  .center {
    .title {
      font-weight: bold;
      font-size: 28px;
      color: #333333;
      line-height: 25px;
      span{
        margin-left: 20px;
      }
    }
    .tip {
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      line-height: 25px;
      &.has-com{
        margin-top: 20px;
      }
      span {
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  a{
    color: #333333 !important;
  }
}
// 新页脚
.new_footer {
  width: 100%;
  height: 480px;
  background: #2b2b2b;
  margin: 0 auto;
}
.link_list {
  // width: 1200px;
  margin: 0 100px;
  // margin: 0 auto;
  .list_first {
    display: flex;
    justify-content: space-between;
    padding-top: 79px;
    border-bottom: 1px solid rgba(255,255,255,.3);
    padding-bottom: 60px;
    .logo {
      width: 204px;
      p.img {
        width: 115px;
        img {
          width: 100%;
          height: auto;
        }
      }
      p.text {
        margin-top: 21px;
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 25px;
      }
    }
    .ul_list {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 120px;
      ul {
        li:first-child {
          font-size: 14px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 17px;
        }
        li {
          line-height: 25px;
          font-size: 14px;
          color: #fff;
          a {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.6;
          }
        }
      }
      ul.aboutUs {
        li {
          position: relative;
        }
        div.code_img {
          position: absolute;
          right: -110px;
          bottom: -120px;
          z-index: 9;
          display: none;
          img{
            width: 120px;
            height: auto;
          }
        }
        li:hover {
          div.code_img {
            display: block;
          }
        }
      }
    }
  }
  .list_sec {
    border-bottom: 1px solid rgba(255,255,255,.3);
    margin-top: 28px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    p.title {
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    ul {
      margin-left: 73px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        // flex: 1;
        // text-align: left;
        margin-left: 20px;

        a {
          font-size: 14px;
          color: #fff;
          opacity: 0.6;
        }
      }
    }
  }
  .deep_text {
    padding-top: 36px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
  }
}
.code_box{
  display: flex;
  div{
    margin-right: 30px;
    p.img{
      width: 90px;
      height: 90px;
      img{
        width: 100%;
      }
    }
    p.text{
      text-align: center;
      color: #fff;
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
.fenzhan_107{
  .bottom{
    background: #EDEDED;
  }
  .title,
  .tip,
  .tip a{
    color: #333 !important;
  }
}
</style>
