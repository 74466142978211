import wxShare from "@/utils/wx.js";
const showWxShare =(data)=> {
    // const pageUrl =  encodeURIComponent(location.href.split("#")[0]);
    const pageUrl =  encodeURIComponent('https://npx.kskstudy.com/');
    const shareObj = {
      title:data.title, // 分享标题
      link: encodeURIComponent('https://npx.kskstudy.com/'), // 分享链接
      desc: data.desc, // 分享描述
      imgUrl:data.img?data.img:'https://new-jnqg.oss-cn-beijing.aliyuncs.com/uploads/20220509/e715726c144b8b319fe581f00d5e5e36.png', // 分享图标
      link2:location.href
    };
    //console.log(shareObj,'kkk')
    wxShare(pageUrl, shareObj);
    
}
export default showWxShare